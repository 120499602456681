import React, { useEffect, useState } from 'react';
import { milliToString, timeToMilli } from './TimeUtil';
import makeStyles from '@mui/styles/makeStyles';
import { Box } from '@mui/material';
import { useKioskGreen } from './UseKiosk';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((/* theme */) => ({
  root: {},
}));

export const ElapsedClock = ({ start, inProgress, finish }: { start: string; inProgress: boolean; finish: string }) => {
  const classes = useStyles();
  const [, forceUpdate] = useState(0);
  const [kioskGreen] = useKioskGreen();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const startMilli = timeToMilli(start);
  const now = new Date();
  let nowMilli = now.getTime() - now.getTimezoneOffset() * 60 * 1000;
  nowMilli = nowMilli % (24 * 3600 * 1000);
  const delta = nowMilli - startMilli;
  const elapsed = milliToString(delta, false, 1);

  useEffect(() => {
    if (!start) {
      return;
    }
    const timer = setInterval(() => {
      forceUpdate((prior) => prior + 1);
    }, 100);
    return () => {
      clearTimeout(timer);
    };
  }, [start, inProgress]);

  let elapsedColor = `#${searchParams.get('elapsedColor') || 'fff'}`;
  let elapsedBg = `#${searchParams.get('elapsedBg') || '000'}`;
  const elapsedSize = Number(searchParams.get('elapsedSize') || 32);
  const elapsedZero = searchParams.get('elapsedZero');

  let text = '---';
  if (start) {
    if (inProgress) {
      text = finish ? '---' : elapsed;
    }
  } else {
    text = '0.0';
  }
  // const text = inProgress ? (finish ? '---' : elapsed) : '0.0';

  if (text === '0.0' && elapsedZero) {
    elapsedBg = `#${elapsedZero}`;
    elapsedColor = `#${elapsedZero}`;
  } else if (text === '---') {
    elapsedColor = '#0000';
  }

  const boxStyle: React.CSSProperties = { color: elapsedColor };
  if (!kioskGreen) {
    boxStyle.backgroundColor = elapsedBg;
  }

  return (
    <Box className={classes.root} style={boxStyle} fontFamily="Monospace" fontSize={elapsedSize} textAlign="right">
      {text}
    </Box>
  );
};
